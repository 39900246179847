<template>
    flskfjslfkjslf
  </template>

  <script>
  export default {
    name: "Payments"
  }
  </script>

  <style lang="scss">

  </style>
