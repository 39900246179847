<template>
  <TextField
    :type="'text' ? !isShowPass : 'password'"
    :placeholder="$props.placeholder"
    :iconLeft="$props.iconLeft"
    :isShowPass="isShowPass"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :isInvalid ="isInvalid"
    ariaAutocomplete="list"
    :isPasswordField="true"
    :invalidText="$props.invalidText || defaultInvalidText"
  >
    <template v-slot:iconLeft>
      <span class="wg-ffi-icon">
        <img
          src="../../assets/icons/lock.svg"
          alt="mail email"
        />
      </span>
    </template>
    <template v-slot:iconRight>
    <span class="text-field-icon-right">
      <img
        v-show="isShowPass"
        @click="isShowPass = !isShowPass"
        :src="showPass"
        alt="show password"
      />
      <img
        v-show="!isShowPass"
        @click="isShowPass = !isShowPass"
        :src="hidePass"
        alt="hide password"
      />
    </span>
    </template>
  </TextField>
</template>

<script>
import hidePass from '@/assets/icons/hide-password.svg';
import showPass from '@/assets/icons/show-pass.svg';
import { defineComponent } from 'vue';
import TextField from '../../components/forms/TextField.vue';

export default defineComponent({
  name: 'PasswordField',
  components: {
    TextField
  },
  emits: ['update:modelValue', 'update:invalidText'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    invalidText: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: 'example@mail.com'
    },
    iconLeft: {
      type: String,
      default: 'wg-ffi-sms-fill'
    }

  },
  data () {
    return {
      isInvalid: false,
      isShowPass: false,
      defaultInvalidText: '',
      showPass,
      hidePass
    }
  },
  methods: {
    updateModelValue (e) {
      this.$emit('update:modelValue', e)
      this.isInvalid = e.length < 8
      this.defaultInvalidText = this.isInvalid ? this.$t('invalid.password') : ''
      this.$emit('update:invalidText', this.defaultInvalidText)
    }
  }
})
</script>

<style lang="scss">
.wg-field-wrap {
  .wg-field-box {
    position: relative;

    .wg-ffi-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      width: 18px;
      text-align: center;
      left: 15px;
    }

    .wg-field-icon-left {
      left: 10px;
    }

    .wg-field-icon-right {
      right: 10px;
    }
  }

  &.is-icon-left {

    .wg-field-input {
      padding-left: 36px;
    }
  }

 .text-field-icon-right {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   width: 24px;
   height: 24px;
   right: 15px;

   img {
     cursor: pointer;
     opacity: .5;
     &:hover {
       opacity: .7;
     }
   }
 }

  .wg-field-input {
    height: 50px;
    padding: 10px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #ACF601;
    background: rgba(44, 43, 43, 0.00);
    transition:
    background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;

    &:focus {
      outline: none;
      border-radius: 10px;
    }

    &:disabled {
      background-size: 4.24px 4.24px;

      .wg-ffi-icon {
        opacity: 0.3;
      }
    }
  }

  .bottom,
  .top,
  .left,
  .right {
    display: none;
  }
}
</style>
