<template>
  <div class="c-registration-signup c-registration-signup--overflow">
    <div class="c-registration-head">
      <p class="c-registration-head_title">
        {{ $t('modals.signUp') }}
      </p>
    </div>
    <form @submit.prevent="handleSubmit" class="c-registration_body">
      <EmailField
        class="c-registration_body_field"
        v-model.trim="userEmail"
        :is-required="true"
        name="email"
        @update:invalidText="isInvalid"
        placeholder="Email@gmail.com"
      />
      <PasswordField
        class="c-registration_body_field"
        type="password"
        :is-required="true"
        v-model.trim="password"
        name="password"
        placeholder="Password"
      />
      <PasswordField
        class="c-registration_body_field"
        type="password"
        :is-required="true"
        name="repeat-password"
        v-model.trim="repeatPassword"
        placeholder="Repeat password"
      />
      <span class="c-registration_body-field-error">
        {{ authErrorForHtml }}
      </span>

      <div class="c-registration_confirmation">
        <Checkbox
          v-model="confirmAgreement"
          class="m-registration_confirmation-checkbox"
          id="confirm"
          label="Confirm Your Agreement To Our Terms Of Use And Privacy Policy"
        />
      </div>
      <div class="c-registration_footer">
        <button
          type="submit"
          :disabled="!confirmAgreement"
          :class="{'c-registration_footer-login-btn--disabled': !confirmAgreement}"
          class="c-registration_footer-login c-registration_footer-login-btn"
        >
          {{ $t('modals.signUp') }}
        </button>
        <button
          type="submit"
          @click="toLogin"
          :disabled="!confirmAgreement"
          :class="{'c-registration_footer-siging-btn--disabled': !confirmAgreement}"
          class="c-registration_footer-siging"
        >
          {{ $t('modals.signIn') }}
        </button>

        <div class="separator"><span>Or</span></div>

        <div>
          <a
            class="c-registration_footer-google-link"
            :href="`https://api.dressdown.ai/auth/discord?referral_token=${getReferralLinkForRegistration}`"
          >
            <DiscordButton />
          </a>
          <a
            class="c-registration_footer-google-link"
            :href="`https://api.dressdown.ai/auth/google?referral_token=${getReferralLinkForRegistration}`"
          >
            <GoogleButton />
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DiscordButton from "@/components/DiscordButton.vue";
import EmailField from "@/components/forms/EmailField";
import PasswordField from "@/components/forms/PasswordField.vue";
import GoogleButton from "@/components/GoogleButton.vue";
import { computed, onMounted, ref } from 'vue';
import { useI18n } from "vue-i18n";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Checkbox from "@/components/forms/Checkbox.vue";

export default {
  name: "AuthFormSignUp",
  components: {
    EmailField,
    PasswordField,
    DiscordButton,
    GoogleButton,
    Checkbox
  },
  setup (props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const { locale } = useI18n()

    const userEmail = ref('')
    const username = ref('')
    const password = ref('')
    const firstName = ref('')
    const lastName = ref('')
    const repeatPassword = ref('')
    const referralEmail = ref('')
    const isInvalidEmail = ref('')
    const errors = ref({})

    const confirmAgreement = ref(false);

    const isFormValid = () => {
      return username.value && userEmail.value && password.value && repeatPassword.value
    }

    const validateForm = () => {
      errors.value = {};

      if (isInvalidEmail.value !== '') {
        errors.value.userEmail = "Invalid email format.";
      }
      if (!repeatPassword.value && password.value !== '' && repeatPassword.value !== '') {
        errors.value.repeatPassword = "Please repeat your password.";
      } else if (password.value.length < 8 && password.value) {
        errors.value.repeatPassword = "Password should be more than 8 symbols";
      } else if (password.value !== repeatPassword.value && password.value && repeatPassword.value) {
        errors.value.repeatPassword = "Passwords do not match.";
      }
    }

    const disabledSubmit = computed(() => {
      validateForm()
      return Object.keys(errors.value).length > 0 || !isFormValid()
    })

    const authErrorForHtml = computed(() => {
      const errorMessages = Object.values(errors.value).filter(Boolean);
      return errorMessages.length > 0 ? errorMessages[0] : null;
    })
    const getReferralLinkForRegistration = computed(() => {
      return store.getters.getReferralLinkForRegistration
    })

    const clearData = () => {
      username.value = ''
      userEmail.value = ''
      password.value = ''
      referralEmail.value = ''
    }

    const onClose = () => {
      clearData()
      store.commit('removeAllModal')
      emit('closeModal')
    }
    const isInvalid = (value) => {
      return isInvalidEmail.value = value
    }

    const handleSubmit = () => {
      validateForm()

      if (!disabledSubmit.value) {
        const formData = new FormData()
        formData.append('email', userEmail.value)
        formData.append('password', password.value)
        formData.append('referral_token', getReferralLinkForRegistration.value)
        store.dispatch('registration', formData)
        const img = new Image();
        img.src = "//tsyndicate.com/api/v2/cpa/192272/pixel.gif";
        img.onload = () => {
          console.log("Registration tracking pixel sent.");
        };

        router.push(`/${locale.value}/confirm-email`)
      }
    }

    onMounted(() => {
      errors.value = {};
    })

    const toLogin = () => {
      store.commit('addModal', 'Login')
    }

    return {
      toLogin,
      handleSubmit,
      isInvalid,
      validateForm,
      onClose,
      authErrorForHtml,
      firstName,
      lastName,
      userEmail,
      username,
      password,
      repeatPassword,
      referralEmail,
      disabledSubmit,
      getReferralLinkForRegistration,
      confirmAgreement,
    }
  }
}
</script>

<style lang="scss" scoped>
.c-registration-signup {
  margin-bottom: 30px;
}
.c-registration_body-field-error {
  font-size: 12px;
  line-height: 1.333;
  color: red;
  display: block;
  margin-bottom: 10px;
}
.m-registration_confirmation-checkbox {
  margin-bottom: 15px;
}
.c-registration_footer-login,
.c-registration_footer-siging {
  height: 50px;
  max-height: initial;
}
.separator {
  width: 100%;
  position: relative;
  height: 2px;
  background-color: #ACF601;
  margin: 30px 0;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: rgb(22, 22, 22);
  }
}
.c-registration_footer-google-link {
  width: 100%;

  .b-discord {
    width: 100%;
    max-width: initial;
    height: 50px;
    border-radius: 10px;
  }

  .gsi-material-button {
    width: 100%;
    max-width: initial;
    height: 50px;
    border-radius: 10px;
    background-color: #fff;
  }

  :deep {
    .gsi-material-button-content-wrapper {
      justify-content: center;
    }
    .gsi-material-button-contents {
      color: #000;
      font-weight: 600;
      flex-grow: 0;
    }
  }
}
</style>
