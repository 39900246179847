<template>
  <TextField
    type="email"
    :placeholder="$props.placeholder"
    :iconLeft="$props.iconLeft"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :isInvalid ="isInvalid"
    ariaAutocomplete="list"
    :invalidText="$props.invalidText || defaultInvalidText"
  >
  <template v-slot:iconLeft>
    <span class="wg-ffi-icon">
      <img
        src="../../assets/icons/mail-empty.svg"
        alt="mail email"
      />
    </span>
  </template>
  </TextField>
</template>

<script>
import { defineComponent } from 'vue';
import TextField from '../../components/forms/TextField.vue';

const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
export default defineComponent({
  name: 'EmailField',
  components: {
    TextField
  },
  emits: ['update:modelValue', 'update:invalidText'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    invalidText: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: 'example@mail.com'
    },
    iconLeft: {
      type: String,
      default: 'wg-ffi-sms-fill'
    }

  },
  data () {
    return {
      isInvalid: false,
      defaultInvalidText: ''
    }
  },
  methods: {
    updateModelValue (e) {
      this.$emit('update:modelValue', e)
      this.isInvalid = !regexEmail.test(e)
      this.defaultInvalidText = this.isInvalid ? this.$t('invalid.email') : ''
      this.$emit('update:invalidText', this.defaultInvalidText)
    }
  }
})
</script>

<style lang="scss">
.wg-field-wrap {

  .wg-field-box {
    position: relative;

    .wg-ffi-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      width: 18px;
      text-align: center;
      left: 15px;

      img {
        width: 100%;
      }
    }

    .wg-field-icon-left {
      left: 10px;
    }

    .wg-field-icon-right {
      right: 10px;
    }
  }

  .wg-field-input {
    height: 50px;
    padding: 10px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #ACF601;
    background: rgba(44, 43, 43, 0.00);
    transition:
    background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;

    &:focus {
      outline: none;
      border-radius: 10px;
    }

    &:disabled {
      background-size: 4.24px 4.24px;

      .wg-ffi-icon {
        opacity: 0.3;
      }
    }
  }

  &.is-icon-left {

    .wg-field-input {
      padding-left: 36px;
    }
  }

  &.is-icon-right {

    .wg-field-input {
      padding-right: 36px;
    }
  }

  .wg-field-error {
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.333;
    color: red;
  }

  &:not(:last-child) {
    margin-bottom: 9px;
  }

  &~.wg-field-wrap {

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &~.wg-button {

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &~.wg-checkbox-box {

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  input::placeholder {
    color: hsla(0, 0%, 100%, 0.6);
  }

  .bottom,
  .top,
  .left,
  .right {
    display: none;
  }
}
</style>
